import { Injectable } from '@angular/core';
import { Router, Route, CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ALNIdentityAuthService } from '../aln-identity/aln-identity-auth.service';
import { stsLoginUri } from 'app/core/constants/url.constants';

@Injectable()
export class ALNIdentityAuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private router: Router, 
        private oidcSecurityService: OidcSecurityService, 
        private authService: ALNIdentityAuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkUser();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
      }

    canLoad(state: Route): Observable<boolean> {
        return this.checkUser();
    }

    private checkUser(): Observable<boolean> {
        if (!this.authService.isEnableIdentityAuth) {
            return of(true);
        }

        return this.oidcSecurityService.getIsAuthorized().pipe(
            map((isAuthorized: boolean) => {
                if (!isAuthorized) {
                    location.href = stsLoginUri;
                    return false;
                }
                return true;
            })
        );
    }
}