export const environment = {
    production: false,
    hmr: false,
    webApiBaseUri: 'https://api.efiling.qa.alncorp.com/api',
    efilingwebUri: 'https://central.efiling.qa.alncorp.com',
    o365ClientID: '252c7cc1-308a-465e-bea8-7d3bded0715b',
    o365RedirectUri: 'https://apps.qa.alncorp.com/apps/office365/configure',
    smartDocketsUri: 'https://smartdockets.qa.alncorp.com/appgateway/home/',
    centralAPIBaseUri: 'https://api.apps.dev.alncorp.com',
    crmtUri: 'https://courtrules.qa.alncorp.com/',
    fwfUri: 'https://qa.formsworkflow.com/',
    eDocketsUri:'https://edockets.qa.alncorp.com',
    docketAlertsUri:'http://qa.docketalerts.com',

    stsUrl: 'https://identity.qa.alncorp.com/',
    clientId: 'centralApp',
    redirectRoute: 'apps/dashboards/analytics',
    clientUrl: 'https://apps.qa.alncorp.com',
    silentRenewRoute: 'silent-renew.html',
    logConsoleDebugActive: true
};
