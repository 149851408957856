import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, debounceTime } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { uscfLoginDTO } from '../models/uscfLoginDTO';
import { environment } from 'environments/environment';

const BASE_URL_WEBAPI = environment.webApiBaseUri;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    userEntity: uscfLoginDTO = new uscfLoginDTO();
    private currentUserSubject: BehaviorSubject<uscfLoginDTO>;

    constructor(
        private http: HttpClient
    ) {
        this.currentUserSubject = new BehaviorSubject<uscfLoginDTO>(JSON.parse(localStorage.getItem('currentUser')));
    }

    public get currentUserValue(): uscfLoginDTO {

        return this.currentUserSubject.value;
    }

    public getCurrentUserInfo(userguid) {
        return this.http.get<any>(`${BASE_URL_WEBAPI}/users/user-info/${userguid}`)
            .pipe(map(response => {
                this.userEntity = response.data;
                this.userEntity.profileImageURL = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
                localStorage.setItem('currentUser', JSON.stringify(this.userEntity));
                this.currentUserSubject.next(this.userEntity);
                return response;
            }));
    }

    /* public RegisterCurrentUserSetup() {
        this.userEntity.fName="Vijay"
        this.userEntity.lName="Akula"
        this.userEntity.userName="vakula@alncorp.com"
        this.userEntity.profileImageURL="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

        localStorage.setItem('currentUser', JSON.stringify(this.userEntity));
        this.currentUserSubject.next(this.userEntity);
    } */
}